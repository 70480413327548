<template>
  <th-page-wrapper>
    <div class="th-container">
      <img class="image" :src="imgUrl" alt="error image" />
      <div class="title">
        {{ title }}
      </div>
      <div class="subtitle">
        {{ subTitle }}
        <slot name="subtitle" />
      </div>
      <div class="extra-content">
        <slot />
      </div>
    </div>
  </th-page-wrapper>
</template>

<script>
export default {
  name: 'ErrorTemplate',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false,
      default: undefined
    },
    imgUrl: {
      type: String,
      required: true
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style scoped>
.th-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  margin-top: 30px;
}

.title {
  font-size: larger;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.subtitle {
  text-align: center;
  white-space: pre-line;
  /* FIXME disabling margin as `pre-line` introduces one blank line at the top. if someone finds a proper fix, let me know - ren */
  /*margin-top: 20px;*/
}

.extra-content {
  margin-top: 20px;
}
</style>
