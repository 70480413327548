<template>
  <error-template
    img-url="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/error-pages/401.svg"
    :title="$t('pages.error.401.title')"
    :sub-title="$t('pages.error.401.subtitle')"
  >
    <el-button type="primary" @click="goLogin">
      {{ $t('pages.error.401.button') }}
    </el-button>
  </error-template>
</template>

<script>
import ErrorTemplate from './components/error-template'
export default {
  name: 'Page401',
  components: { ErrorTemplate },
  methods: {
    goLogin() {
      // we're directing to logout in order to clear authorization, and have a login screen without sidebar & header
      this.$router.replace({ path: '/logout' })
    }
  }
}
</script>

<style scoped></style>
